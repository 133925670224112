<template>
    <div>
        <div class="ms-panel-header header-mini">
            <div class="bg-opacity-25 bg-secondary fw-bold invoice-title py-2 text-center mb-2">
                <h6>PAYMENT INVOICE</h6>
            </div>
        </div>
        <div class="ms-panel-body">
            <!-- Invoice To -->
            <div class="row" v-if="transaction.patient">
                <div class="col-md-5">
                    <div class="invoice-address" v-if="transaction.patient">
                        <p><strong>Invoice No: {{ transaction.invoice_no }}</strong></p>
                        <p>
                            <strong>Patient: </strong>{{ transaction.patient.name +" | "+transaction.patient.id_number }}<br>
                        </p>
                    </div>
                </div>
                <div class="col-md-2 d-none d-sm-block">
                    <img style="height: 50px" :src="transaction.qr_code">
                </div>
                <div class="my-2 my-sm-0 col-md-5 d-flex flex-column align-items-sm-end">
                    <p>
                        <strong> Date & Time :</strong>{{ transaction.transaction_date |dateTimeFormat }}
                    </p>
                    <p>
                        <strong>Phone: </strong>{{ transaction.patient.phone }}
                    </p>
                </div>
            </div>
            <!-- Invoice item Table -->
            <div class="ms-invoice-table table-responsive">
                <table class="table">
                    <thead>
                    <tr class="text-capitalize bg-secondary bg-opacity-25">
                        <th class="text-left">Service</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th class="float-end">Subtotal</th>
                    </tr>
                    </thead>
                    <tr v-for="(transaction_item, index) in transaction.transaction_items">
                        <td class="text-left">{{
                                transaction_item.service ? transaction_item.service.name
                                                         : ''
                            }}
                        </td>
                        <td> {{ transaction_item.quantity }}</td>
                        <td> {{  transaction_item.unit_price | numberFormat }}</td>
                        <td class="float-end">
                            {{ transaction_item.unit_price * transaction_item.quantity | numberFormat }}
                        </td>
                    </tr>

                    <tr>
                        <td style="text-align: center; vertical-align: middle" colspan="3">
                            <label :class="`payment-css-${transaction.payment_status}`"
                                   class="badge"
                                   style="text-transform: uppercase;font-weight: 800;border: 1px solid;padding: 14px;">
                                {{ transaction.payment_status }}
                            </label>
                            <br>
                            <br>
                            <br>
                            <p style="text-transform: uppercase">{{ transaction.total_paid | inWords }}</p>
                            <p>{{ transaction.note }}</p>
                        </td>
                        <td>
                            <table class="table text-right">
                                <tr>
                                    <th style="border-top: 0px" class="text-right font-weight-bolder">
                                        Visit fee:
                                    </th>
                                    <th style="border-top: 0px" class="font-weight-bolder float-end">
                                        {{ transaction.visit_fee | numberFormat }}
                                    </th>
                                </tr>
                                <tr>
                                    <th style="border-top: 0px" class="text-right font-weight-bolder">
                                        Sub Total:
                                    </th>
                                    <th style="border-top: 0px" class="font-weight-bolder float-end">
                                        {{ transaction.sub_total | numberFormat }}
                                    </th>
                                </tr>
                                <tr v-if="transaction.discount_amount>0">
                                    <th class="text-right font-weight-bolder">
                                        Discount:
                                    </th>
                                    <th class="font-weight-bolder float-end">
                                        {{ transaction.discount_amount | numberFormat }}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-right font-weight-bolder">
                                        Grand Total:
                                    </th>
                                    <th class="font-weight-bolder float-end">
                                        {{ transaction.final_total | numberFormat }}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-right font-weight-bolder">
                                        Total Paid:
                                    </th>
                                    <th class="font-weight-bolder float-end">
                                        {{ transaction.total_paid | numberFormat }}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-right font-weight-bolder">
                                        Total Due
                                    </th>
                                    <th class="font-weight-bolder float-end">
                                        {{ transaction.total_due | numberFormat }}
                                    </th>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr class="d-none" v-if="type && transaction.report_delivery_date">
                        <td colspan="3" style="text-align: center">
                            <strong>Report Delivery After : {{
                                    transaction.report_delivery_date | dateTimeFormat
                                }} </strong>
                        </td>
                    </tr>
                    <tr class="d-none">
                        <td colspan="2" style="text-align: center">
                            <strong v-if="type">Remark : {{ transaction.additional_notes }} </strong>
                        </td>
                        <td>
                            <strong>Created By : </strong>{{
                                transaction.created_user ? transaction.created_user.name : ''
                            }}
                        </td>
                    </tr>
                    <tr v-if="type" class="d-none">
                        <td colspan="3" style="text-align: center">&nbsp;
                            <!--              <strong>রিপোর্ট ডেলিভারি প্রতিদিন সন্ধ্যা ৭ টার পরে সেফওয়ে আই এবং ডেন্টাল হাসপাতালের এর নিচতলায়
                                            দেয়া হয় ।</strong>-->
                        </td>
                    </tr>

                </table>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentCreateModal from "./payment/modal/Create";
import PaymentListModal   from "./payment/modal/List";

export default {
    name      : "TransactionDetails",
    props     : ['transaction', 'type'],
    components: {PaymentListModal, PaymentCreateModal},
    data() {
        return {
            payment_list_modal  : false,
            transaction_payments: [],
        }
    },
    mounted() {
    },
    methods: {
        printInvoice() {
            this.$htmlToPaper('printInvoice');
        },
    }
}
</script>
<style scoped>
.payment-css-paid {
    color: #fff;
    background-color: #28a745;
}

.payment-css-partial {
    color: #fff;
    background-color: #ffc107;
}

.payment-css-due {
    color: #fff;
    background-color: #dc3545;
}
.table > :not(:first-child) {
    border-top: 1px solid #ddd;
}
</style>
