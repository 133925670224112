<template>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <div class="ms-panel my-sm-5 my-3 px-3 py-2 rounded shadow">
                    <div class="invoice-buttons d-flex gap-2 my-2 justify-content-end flex-column flex-sm-row">
                        <a @click.prevent="$router.go(-1)" href="" class="btn btn-primary me-auto">Back</a>
                        <update-action v-if="$root.$can('bill', 'bill-update')"
                                       to_name="invoiceEdit" :params="{ id: transaction.id }"/>
                        <button @click="openPaymentCreateModal(transaction)" class="btn btn-success mr-2"
                                style="text-transform: capitalize">
                            Add Payment
                        </button>
                        <button @click="openPaymentListModal(transaction)" class="btn btn-primary mr-2"
                                style="text-transform: capitalize">
                            List Payment
                        </button>
                        <button @click="print(transaction.id)" class="btn btn-secondary mr-2"
                                style="text-transform: capitalize">
                            Print Invoice
                        </button>
                    </div>
                    <invoiceItems :transaction="transaction" :type="true"/>
                    <!--transaction payment section-->
                    <div class="table-responsive">
                        <table class="table table-hover text-right">
                            <thead class="bg-secondary bg-opacity-25">
                            <tr>
                                <th class="text-left" scope="col">#</th>
                                <th class="text-left" scope="col">Date</th>
                                <th class="text-left" scope="col">Payment Method</th>
                                <th class="text-left" scope="col">Amount</th>
                                <th class="text-left" scope="col">Received By</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template
                                v-if="transaction.transaction_payments && transaction.transaction_payments.length">
                                <tr v-for="(payment, index) in transaction.transaction_payments">
                                    <th class="text-left" scope="row">{{ ++index }}</th>
                                    <td class="text-left">{{ payment.paid_on |dateFormat }}</td>
                                    <td class="text-left" style="text-transform: capitalize">{{ payment.method }}</td>
                                    <td class="text-left">{{ payment.amount | numberFormat }}</td>
                                    <td class="text-left">{{ payment.created_by ? payment.created_by.name : '' }}</td>
                                </tr>
                            </template>
                            <template
                                v-if="transaction.transaction_payments && !transaction.transaction_payments.length">
                                <tr>
                                    <th class="text-center font-weight-bolder" colspan="100%">No data
                                        found
                                    </th>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <payment-create-modal ref="payment_create_modal" v-if="payment_create_modal"/>
            <payment-list-modal ref="payment_list_modal" v-if="payment_list_modal"/>
            <InvoicePrintView ref="print_view" v-if="print_view"/>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import PaymentCreateModal  from "./payment/modal/Create";
import PaymentListModal    from "./payment/modal/List";
import InvoicePrintView    from "./PrintView";
import invoiceItems        from "./invoiceItems";

export default {
    name      : "TransactionDetails",
    components: {PaymentListModal, PaymentCreateModal, InvoicePrintView, invoiceItems},
    data() {
        return {
            print_view          : false,
            isLoading           : true,
            transaction         : {},
            payment_create_modal: false,
            payment_list_modal  : false,
            transaction_payments: [],
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        print(invoice_id) {
            this.print_view         = true;
            const component_created = setInterval(() => {
                if (this.$refs['print_view']) {
                    this.$refs['print_view'].getInvoice(invoice_id);
                    clearInterval(component_created);
                }
            }, 100);
        },
        getList() {
            ApiService.get(`/transactions/${this.$route.params.id}`).then((res) => {
                this.transaction = res.data.data;
                this.isLoading   = false;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        openPaymentCreateModal(transaction) {
            if (transaction.total_due > 0) {
                this.payment_create_modal = true;
                const component_created   = setInterval(() => {
                    if (this.$refs['payment_create_modal']) {
                        let payment_form                            = {
                            transaction_id: transaction.id,
                            method        : 'cash',
                            amount        : transaction.total_due,
                            max_amount    : transaction.total_due,
                            paid_on       : new Date(),
                        };
                        this.$refs['payment_create_modal'].editMode = false;
                        this.$refs['payment_create_modal'].form     = payment_form;
                        this.$refs['payment_create_modal'].openPaymentModal();
                        clearInterval(component_created);
                    }
                }, 100);
            } else {
                NotificationService.success('Payment Completed, Thanks');
            }
        },
        openPaymentListModal(transaction) {
            this.payment_list_modal = true;
            const component_created = setInterval(() => {
                if (this.$refs['payment_list_modal']) {
                    this.transaction_payments = transaction.transaction_payments;
                    this.$refs['payment_list_modal'].openPaymentModal();
                    clearInterval(component_created);
                }
            }, 100);
        }
    }
}
</script>
<style scoped>
.payment-css-paid {
    color: #fff;
    background-color: #28a745;
}

.payment-css-partial {
    color: #fff;
    background-color: #ffc107;
}

.payment-css-due {
    color: #fff;
    background-color: #dc3545;
}
</style>
